import React, { useEffect, useRef } from "react";
import "../Styles/CoreOfferings.css";
import implementation from "../Images/Core/implementation.png"
import ams_support from "../Images/Core/ams_support.png"



const CoreService = () => {
  const sections = [
    {
      image: implementation,
      heading: "Implementation (Transformation to Cloud SuccessFactors)",
      subtitle: "Seamless Cloud Transformation for Future-Ready HR",
      introText: "Our expert-led implementation services ensure a smooth transition to SuccessFactors Cloud, unlocking the full potential of your HR operations. We provide:",
      content: [
        "End-to-End Transformation: From initial assessment to full deployment, we manage every step of the cloud adoption process",
        "Tailored Implementation Plans: Custom solutions designed around your unique business requirements and industry challenges",
        "Expertise Across All SuccessFactors Modules: Whether it’s Employee Central, Performance & Goals, Compensation, or Learning & Development, we bring specialized knowledge to ensure optimal module configuration",
        "Fast and Efficient Go-Live: Minimizing disruption with smooth system integration, data migration, and end-user adoption",
        "Long-Term Value: Ensure your organization is not only ready for today but also equipped to scale with future HR needs and business growth"
      ]
    },
    {
      image: ams_support,
      heading: "AMS Support",
      subtitle: "Comprehensive AMS Support for Ongoing Success",
      introText: "Our Application Management Services (AMS) provide continued support, ensuring your SuccessFactors environment runs at peak performance. Our subscription-based plans offer:",
      content: [
        "Expert-Driven Support: Access to a dedicated team of specialists across various SuccessFactors modules, ensuring issues are resolved quickly and effectively",
        "Proactive Monitoring & Maintenance: Regular updates, troubleshooting, and system health checks to maintain optimal performance",
        "Flexible and Scalable Plans: Monthly subscription bundles designed to scale with your evolving needs, ensuring you get the right level of support at every stage",
        "Seamless System Integrations & Enhancements: Continuous improvements through custom extensions, integrations, and process automation to keep your HR systems aligned with changing business requirements",,
        "Ongoing Value and Cost-Efficiency: Significant long-term discounts for extended commitments, ensuring you receive high-quality service at the best possible value",
        "Data-Driven Insights & Analytics: Leverage the power of analytics to make informed decisions and continuously enhance HR performance"
      ]
    }
  ];
 
  // Refs for sections
  const sectionRefs = useRef(sections.map(() => React.createRef()));
 
  useEffect(() => {
    // Initialize Intersection Observer
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        const section = entry.target;
        const heading = section.querySelector(".core-offerings-heading");
 
        if (heading) {
          if (entry.isIntersecting) {
            // Add animation class when the section is in view
            heading.classList.add("slide-in-text");
          } else {
            // Remove animation class when the section is out of view
            heading.classList.remove("slide-in-text");
          }
        }
      });
    }, { threshold: 0.5 }); // Trigger when 50% of the section is visible
 
    sectionRefs.current.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current); // Observe the entire section
      }
    });
 
    return () => {
      observer.disconnect(); // Cleanup observer on component unmount
    };
  }, []);
 
  return (
    <div className="core-offerings-tiles-wraper">
    <div className="core-offerings-container" style={{
        paddingTop: "0px",
        paddingBottom: "0px"
    }}>
      {sections.map((section, index) => (
        <div
          className={`core-offerings-section ${index % 2 === 0 ? "reversed" : ""}`}
          key={index}
          ref={sectionRefs.current[index]} // Observe this section
        >
          <div className="core-offerings-image">
            <img className = "core-offerings-img" src={section.image} alt={section.heading} />
            <div className="core-offerings-heading">
              <h2>{section.heading}</h2>
              <p>{section.subtitle}</p>
            </div>
          </div>
          <div className="core-offerings-content">
            <h3>{section.heading}</h3>
            {/* Use the unique introText for each section */}
            <p>{section.introText}</p>
 
            {/* Dynamically rendered content list */}
            <ul>
              {section.content.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
    </div>
  );
};
 
export default CoreService;
 
 