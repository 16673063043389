import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import "../Styles/tableStyles.css"
import { Link } from 'react-router-dom';
import PricingCard from './PricingCard';

const PricingTable = () => {

  const tileData = [
    {
      title : "Sap Cloud Integration",
      subTitle: "$90 per hour",
      data: [ "Employee Central","Time and Attendance","Global Benifits","Employee Central Payroll"]
    },
    {
      title : "Sap Cloud Integration",
      subTitle: "$90 per hour",
      data: [ "Employee Central","Time and Attendance","Global Benifits","Employee Central Payroll"]
    },
    {
      title : "Sap Cloud Integration",
      subTitle: "$90 per hour",
      data: [ "Employee Central","Time and Attendance","Global Benifits","Employee Central Payroll"]
    }
  ]

  const data = [
    {
      title: "SAP Business Technology Platform",
      sections: [
        {
          title: "Integration",
          subsections: [
            {
              expertise: "SAP Cloud Integration",
              rate: "$90/Hour",
              list: [
                "Custom Integrations from or to SuccessFactors",
                "Concur",
                "Mercer",
                "Fedility",
                "Any other 3rd party Integrations"
              ]
            },
            {
              expertise: "SAP Integration with HCM or S/4",
              rate: "$90/Hour",
              list: [
                "SuccessFactors integration with S4",
                "Org Object Replication",
                "Employee Replication",
                "Employee Central Payroll"
              ]
            }
          ]
        },
        {
          title: "Analytics",
          subsections: [
            {
              expertise: "HR Analytics and Dashboards",
              rate: "$85/Hour", // Dummy rate added
              list: [
                "Employee Performance Analysis", // Dummy data added
                "Workforce Planning", // Dummy data added
                "Diversity and Inclusion Metrics", // Dummy data added
                "Compensation Analysis" // Dummy data added
              ]
            },
            {
              expertise: "Financial Analytics",
              rate: "$95/Hour", // Dummy rate added
              list: [
                "Budgeting and Forecasting", // Dummy data added
                "Profit and Loss Reporting", // Dummy data added
                "Operational Metrics", // Dummy data added
                "KPI Monitoring" // Dummy data added
              ]
            }
          ]
        }
      ]
    }
  ];
  

  return (
    <>
      <PricingCard/>
    </>
  );
};

export default PricingTable;
