import React, { useEffect } from 'react'
import "../Styles/OurCloud.css"
// import teams from "../../../Images/teams.png"
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FaCaretRight } from "react-icons/fa6";
import Carousel from './Carousel';
import Pricing from './Pricing';
import offeringsImage from "../Images/coreOfferings.png"
import CoreOfferings from './CoreOfferings';
import CoreService from './CoreService';
import front_banner from "../Images/front_banner.png"
import one_support from "../Images/one_support.png"
import banner from "../Images/banner.png"

const OurCloud = ({setRegion}) => {
  const { country } = useParams(); // Get the region from the URL parameter
  const navigate = useNavigate();
  const supportedRegions = ['us', 'in', 'sg', 'global'];

  useEffect(() => {
    if (country && !supportedRegions.includes(country.toLowerCase())) {
      setRegion('global');
      navigate(`/global/prebuilt_packages`); // Automatically redirect to global region
    } else if (!country) {
      setRegion('global');
      navigate(`/global/prebuilt_packages`); // Default to global if no country is provided
    }
  }, [country, setRegion, navigate]);

  return (
    <div className='our-cloud'>
      <div className='front-banner-wraper'>
          <img className = "front-banner-img" src = {banner} alt ="front_banner"/>
          <div className='front-banner-wrapper'>
              <div className='front-banner-content'>
                <h1><span style={{
                  color: "orange"
                }}>O</span>NE SUPPORT</h1>
                <p>Simplify human resource processes to drive business success. Identify the right talent, develop and engage your employees with automated, transparent policies and processes, and a digital user experience.</p>
              </div>
          </div>
      </div>
      <div className='core-offerings-wraper'>
          <div className='core-offerings'>
            <h1 className='core-offerings-title'>Core Offerings</h1>
            <p className='core-offerings-content'>At BAPS, we understand that every business has unique priorities and challenges when it comes to leveraging SAP SuccessFactors effectively. That’s why we offer a suite of adaptable service options designed to meet your specific needs and empower your organization at every step of its journey. Whether you’re looking for reliable ongoing support, a strategic roadmap, or a fully managed solution with our Application Management Services (AMS), we tailor our offerings to align seamlessly with your business goals.</p>
            <p className='core-offerings-content'>From optimizing current operations to driving transformational change, our expert team is dedicated to providing the right level of support at precisely the right time. Discover how our personalized solutions can help you enhance efficiency, unlock greater value, and achieve sustainable success. Let’s work together to turn your ambitions into reality.</p>

            <CoreOfferings/>
            <h1 className='core-offerings-title' style={{
              marginBottom: "10px"
            }}>Core Services</h1>
            <CoreService/>

            <h1 className='core-offerings-title'>Subscription</h1>
            <p className='core-offerings-content'>Our subscription plans offer flexible monthly bundles that guarantee access to expert support across a variety of solutions. With specialized experience in modules, system integrations, process automation, custom extensions, analytics, and more, our plans are designed to address your unique needs. By offering attractive discounts for long-term commitments, we ensure that you receive comprehensive, high-quality assistance, empowering your business to stay ahead while efficiently adapting to evolving challenges.</p>
            
            <Carousel/>
            <Pricing/>
          </div>
        </div>
      </div>
    
  )
}

export default OurCloud