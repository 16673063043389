import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Container,
  CssBaseline,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Alert
} from "@mui/material";
import "../../Styles/Login.css";
import { Link } from "react-router-dom";
import Dropdown from "../Dropdown";
import axios from "axios";

const checkboxToServiceIdMap = {
  "SuccessFactor Core Modules Solution and Support Services": "01",
  "SuccessFactors Talent Management Solution and Support Services": "02",
  "SAP Cloud Integration": "03",
  "SAP Integration with HCM or S/4": "04",
  "HR Analytics and Dashboards": "05",
  "Financial Analytics": "06",
  "Automated Workflow Design": "07",
  "Custom Process Automation": "08",
  "Custom Application Development": "09",
  "API Development": "10"
};

const signupUrl = "https://bapsai.com:1443/baps_onesupport_backend_dev-0.0.1-SNAPSHOT/baps/oneSupport/saveUserDetails"
const secretKey = "f65786fb-11eb-462c-a807-2190b25af446";

const SignupForm = () => {

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [formErrors, setFormErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    dropdown: "", // Track dropdown validation error
  });
  const [openDialog, setOpenDialog] = useState(false); // For controlling the dialog visibility
  const [isSelected, setIsSelected] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar visibility
  const [snackbarMessage, setSnackbarMessage] = useState(""); // Snackbar message
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // Severity (error, success, etc.)

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    let errors = {};
    let formIsValid = true;

    // Validate firstName
    if (!formData.firstName) {
      errors.firstName = "First Name is required";
      formIsValid = false;
    }

    // Validate lastName
    if (!formData.lastName) {
      errors.lastName = "Last Name is required";
      formIsValid = false;
    }

    // Validate email
    if (!formData.email) {
      errors.email = "Email is required";
      formIsValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is not valid";
      formIsValid = false;
    }

  // Validate password
  if (!formData.password) {
    errors.password = "Password is required";
    formIsValid = false;
  } else {
    // Check for minimum length
    if (formData.password.length < 8) {
      errors.password = "Password must be at least 8 characters";
      formIsValid = false;
    }

    // Check for at least one uppercase letter
    else if (!/[A-Z]/.test(formData.password)) {
      errors.password = "Password must contain at least one uppercase letter";
      formIsValid = false;
    }

    // Check for at least one lowercase letter
    else if (!/[a-z]/.test(formData.password)) {
      errors.password = "Password must contain at least one lowercase letter";
      formIsValid = false;
    }

    // Check for at least one number
    else if (!/\d/.test(formData.password)) {
      errors.password = "Password must contain at least one number";
      formIsValid = false;
    }

    // Check for at least one special character
    else if (!/[!@#$%^&*(),.?":{}|<>]/.test(formData.password)) {
      errors.password = "Password must contain at least one special character";
      formIsValid = false;
    }
  }

    // Validate confirmPassword
    if (!formData.confirmPassword) {
      errors.confirmPassword = "Please confirm your password";
      formIsValid = false;
    } else if (formData.password !== formData.confirmPassword) {
      errors.confirmPassword = "Passwords do not match";
      formIsValid = false;
    }

    // Validate dropdown (check if any option is selected)
    if (!isSelected) {
      errors.dropdown = "Solution is required";
      formIsValid = false;
    }

    setFormErrors(errors);
    return formIsValid;
  };


  const getSelectedServiceIds = () => {
    return Object.keys(selectedOptions)
      .filter((key) => selectedOptions[key]) // Filter selected checkboxes
      .map((label) => checkboxToServiceIdMap[label]); // Map to serviceId
  };
  

  const preparePayload = () => {
    const selectedServiceIds = getSelectedServiceIds().sort();
  
    return {
      emailId: formData.email,
      firstName: formData.firstName,
      lastName: formData.lastName,
      password: formData.password,
      servicePlans: selectedServiceIds
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior.
  
    // Validate the form first
    if (!validateForm()) return;
  
    const payload = preparePayload(); // Prepare the data to be submitted.
    // console.log("Payload to be sent:", payload);
  
    try {
      // API call using Axios
      const response = await axios.post(signupUrl, payload, {
        headers: {
          "Content-Type": "application/json",
          "api-key": secretKey, // Add your API key here
        },
      });
  
      // Handle successful response
      if ([200, 201].includes(response.status)) {
        // console.log("Signup successful:", response.data);
  
        // Reset form and related state after successful submission
        resetForm();
        setOpenDialog(true);

        // Open confirmation dialog and display success Snackbar
        handleSnackbar("Signup successful! Please check your email for confirmation.", "success");
      }
    } catch (error) {
      // Handle errors using the helper function
      handleError(error);
    }
  };
  
  // Reset form and related state after successful submission
  const resetForm = () => {
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
    });
    setFormErrors((prev) => ({ ...prev, dropdown: "" }));
    setSelectedOptions({});
    setIsSelected(false);
  };
  
  // Handle Snackbar messages and errors
  const handleSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  
  // Helper function for error handling
  const handleError = (error) => {
    let errorMessage = "An unexpected error occurred.";
    
    if (error.response) {
      // Server responded with a status outside 2xx
      errorMessage = error.response.data.message === "User Already Exists"
        ? error.response.data.message
        : "Signup failed. Please try again.";
    } else if (error.request) {
      // Request made but no response received
      errorMessage = "Network error. Please try again later.";
    }
  
    console.error("Error during signup:", errorMessage);
    setFormErrors((prev) => ({ ...prev, apiError: errorMessage }));
    handleSnackbar(errorMessage, "error");
  };
  
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  // useEffect(() => {
  //   console.log("Errors : ", formErrors);
  // }, []);

  return (
    <div className="signup-top-border-container">
    <Container component="main" maxWidth="xs" className="signup-form-wraper">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography 
          component="h1" 
          variant="h5"
        >
          Sign up
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="firstName"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
                value={formData.firstName}
                onChange={handleChange}
                className="text-field"
                helperText={formErrors.firstName && <span className="error">{formErrors.firstName}</span>}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="lastName"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                value={formData.lastName}
                onChange={handleChange}
                className="text-field"
                helperText={formErrors.lastName && <span className="error">{formErrors.lastName}</span>}
              />
            </Grid>
            <Grid item xs={12}>
              <div style={{
                  marginBottom: "16px"
              }}>
              <Dropdown
                isSelected={isSelected}
                setIsSelected={setIsSelected}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
                error={formErrors.dropdown}
              />
              </div>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={formData.email}
                onChange={handleChange}
                className="text-field"
                helperText={formErrors.email && <span className="error">{formErrors.email}</span>}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                value={formData.password}
                onChange={handleChange}
                className="text-field"
                helperText={formErrors.password && <span className="error">{formErrors.password}</span>}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                id="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                className="text-field"
                helperText={formErrors.confirmPassword && <span className="error">{formErrors.confirmPassword}</span>}
              />
            </Grid>
          </Grid>
          <Button
            style={{
              backgroundColor: "rgb(0, 125, 184)",
              borderRadius: "none",
            }}
            className="btn"
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign Up
          </Button>
        </Box>
      </Box>

      {/* Confirmation Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle style={{ color: "Navy" }}>Thank You for Choosing Us</DialogTitle>
        <hr />
        <DialogContent>
          <Typography variant="body1">
            We have received your request. Our team will get back to you within one or two business days.
            Please check your email for confirmation and further steps.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for showing success or error messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
    </div>
  );
};

export default SignupForm;
