// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  font-family: Arial, sans-serif;
}


:root{
  --OFFICIAL_COLOR: orangered;
}



`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,8BAA8B;AAChC;;;AAGA;EACE,2BAA2B;AAC7B","sourcesContent":["*{\n  box-sizing: border-box;\n  margin: 0px;\n  padding: 0px;\n  font-family: Arial, sans-serif;\n}\n\n\n:root{\n  --OFFICIAL_COLOR: orangered;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
