import logo from './logo.svg';
import './App.css';
import OurCloud from './Components/OurCloud';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import JobSearch from './Components/JobSearch/JobSearch';
import Navbar from './Components/Navbar';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Login from './Components/Login/LoginPage';
import Footer from './Components/Footer';
import Cookies from 'js-cookie';
import NotFoundPage from './Components/NotFoundPage';

function App() {
  const [region, setRegion] = useState('');
  // Use useEffect to get the region from the cookie when the component mounts
  useEffect(() => {
    const regionFromCookie = Cookies.get('user_region') || 'us'; // Default to 'us' if not found
    setRegion(regionFromCookie);
    // console.log('Region from cookie:', regionFromCookie);
  }, []);  // Empty dependency array ensures this runs only once on mount

  // Wait until the region is set before rendering routes
  if (!region) {
    return <div>Loading...</div>; // You can show a loading screen until the region is set
  }

  return (
    <div className="App">
      <Navbar/>
      <Routes>
        {/* Redirect to the correct route based on the region */}
        <Route path="/" element={<Navigate to={`/${region}/prebuilt_packages`} replace />} />
        <Route path="/:country/prebuilt_packages" element={<OurCloud setRegion = {setRegion}/>} />
        <Route path="/us/jobsearch" element={<JobSearch/>} />
        <Route path="/us/*" element={<Login />} />
        {/* <Route path="*" element={<NotFoundPage/>} /> */}
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
