import React from 'react'
import { FiPhoneCall } from "react-icons/fi";
import "../Styles/Navbar.css"
import logo from "../Images/logo/logo.png"
import { Link } from 'react-router-dom';
import sap from "../Images/logo/sap.png"

const Navbar = () => {
  return (
    <div className='navbar-wraper'>
      <div className='navbar'>
          <a 
              className='home-link'
              href = "https://bapsconsulting.com/"
              target='blank'
            ><img src = {logo} alt ="baps-logo" className='logo'/></a>
          
          <div className='navbar-links-wraper'>
            <img src = {sap} alt ="baps-logo" className='sap-logo'/>
              {/* <Link
                  className='login-link'
                  to = "/us/login"
                  onClick={()=>window.scrollTo({top:0})}
              >Trusted Client</Link> */}
             <Link
                  className='login-link'
                  to = "/us/signup"
                  onClick={()=>window.scrollTo({top:0})}
              >Sign up</Link>

              <a 
                  className='contact-logo'
                  href = "https://bapsconsulting.com/contact"
              ><FiPhoneCall className='navbar-contact-logo'/></a>
          </div>

      </div>
    </div>
  )
}

export default Navbar