import React, { useEffect, useState } from "react";
import "../Styles/Pricingcard.css";
import { TbBackground } from "react-icons/tb";
import axios from "axios";
import { Link, useParams } from "react-router-dom";

const priceDataUrl = "https://bapsai.com:1443/baps_onesupport_backend_dev-0.0.1-SNAPSHOT/baps/oneSupport/getServicePlans?country=";
const secretKey = "f65786fb-11eb-462c-a807-2190b25af446";


const PricingCard = () => {

    const { country } = useParams(); // Access the country parameter from the URL

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setScreenWidth(window.innerWidth);
      };
  
      // Add event listener to window resize
      window.addEventListener('resize', handleResize);
  
      // Cleanup function to remove the event listener when the component unmounts
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []); // Empty array ensures this runs only once on mount


    const [data, setData] = useState([
        {
          title: "SuccessFactors Solution and Support",
          sections: [
            {
              title: "Core & Talent",
              subsections: [
                {
                  serviceId: "01",
                  expertise: "SuccessFactor Core Modules Solution",
                  cost: "195",
                  currency: "USD",
                  list: [
                    "Employee Central",
                    "Time & Attendance",
                    "Global Benefits",
                    "Employee Central Payroll",
                  ],
                  color: "black"
                },
                {
                  serviceId: "02",
                  expertise: "SuccessFactors Talent Management",
                  cost: "150",
                  currency: "USD",
                  list: [
                    "Performance & Goals",
                    "Compensation & Variable Pay",
                    "Learning Mangement",
                    "Recruiting",
                    "Succession & Development"
                  ],
                  color: "black"
                }
              ],
            }
          ],
        },
        {
          title: "SAP Business Technology Platform",
          sections: [
              {
                  title: "Integration",
                  subsections: [
                    {
                      serviceId: "03",
                      expertise: "SAP BTP Cloud Integration",
                      cost: "160",
                      currency: "USD",
                      list: [
                      "Custom Integrations from or to SuccessFactors",
                        "Concur",
                        "Mercer",
                        "Fidelity",
                        "Any other 3rd party Integrations",
                      ],
                      color: "black"
                    },
                    {
                      serviceId: "04",
                      expertise: "SAP Integration with HCM or S/4",
                      cost: "90",
                      currency: "USD",
                      list: [
                        "SuccessFactors integration with S4",
                        "Org Object Replication",
                        "Real-time Data Sync",
                        "Employee Replication",
                      ],
                      color: "black"
                    },
                  ],
                },
            {
              title: "Analytics",
              subsections: [
                {
                  serviceId: "05",
                  expertise: "HR Analytics and Dashboards",
                  cost: "120",
                  currency: "USD",
                  list: [
                    "Employee Performance Analysis",
                    "Workforce Planning",
                    "Diversity and Inclusion Metrics",
                    "Compensation Analysis",
                  ],
                  color: "black"
                },
                {
                  serviceId: "06",
                  expertise: "SuccessFactor Extension Application",
                  cost: "150",
                  currency: "USD",
                  list: [
                    "Budgeting and Forecasting",
                    "Profit and Loss Reporting",
                    "Operational Metrics",
                    "KPI Monitoring",
                  ],
                  color: "black"
                },
              ],
            },
            {
              title: "Business Process Automation", // New section added
              subsections: [
                {
                  serviceId: "07",
                  expertise: "SuccessFactor Business Process Automation",
                  cost: "130",
                  currency: "USD",
                  list: [
                    "Workflow Creation",
                    "Automated Notifications",
                    "Approval Process Setup",
                  ],
                  color: "black"
                },
                {
                  serviceId: "08",
                  expertise: "Custom Process Automation",
                  cost: "120",
                  currency: "USD",
                  list: [
                    "Advanced Workflow Automation",
                    "Custom Approval Flows",
                    "Third-Party App Integrations",
                  ],
                  color: "black"
                },
              ],
            },
            {
              title: "Build Custom Applications", // New section added
              subsections: [
                {
                  serviceId: "09",
                  expertise: "Custom Application Development",
                  cost: "125",
                  currency: "USD",
                  list: [
                    "Bespoke Application Design",
                    "Custom Web App Development",
                    "Mobile App Integration",
                  ],
                  color: "black"
                },
                {
                  serviceId: "10",
                  expertise: "API Development",
                  cost: "130",
                  currency: "USD",
                  list: [
                    "Building Secure APIs",
                    "Data Integration Solutions",
                    "Real-Time Da ta Sync",
                  ],
                  color: "black"
                },
              ],
            },
          ],
        },
      ])

      const [error, setError] = useState(null);


    
    // Helper function to get the currency symbol dynamically based on the currency
    const getCurrencySymbol = (currency) => {
      switch (currency) {
        case "USD":
          return "$";
        case "SGD":
          return "S$";
        case "INR":
          return "₹";
        default:
          return "";
      }
    };


  // Function to fetch data
  const fetchTileData = async () => {
    setError(null); // Reset error state

    try {
   
      const countryCode = country === "global" ? "us" : country;

      const response = await axios.get(
        `${priceDataUrl}${countryCode}`, // Concatenate the URL properly
        {
          headers: {
            "api-key": secretKey,        
          },
        }
      );
      

      // console.log("Tile Data ----------------- ", response.data); // Log the fetched data

      const servicePlans = response.data.servicePlan;

      // Store the fetched data in localStorage
      window.sessionStorage.setItem(`servicePlans_${country}`, JSON.stringify(servicePlans));

      // Map the service plans to update the data
      setData((prevData) => {
        return prevData.map((platform) => {
          return {
            ...platform,
            sections: platform.sections.map((section) => {
              return {
                ...section,
                subsections: section.subsections.map((subsection) => {
                  // Find the matched service plan for the given country
                  const matchedPlan = servicePlans.find(
                    (plan) => plan.serviceId === subsection.serviceId
                  );

                  return {
                    ...subsection,
                    cost: matchedPlan ? matchedPlan.cost : subsection.rate, // Update rate with the fetched cost
                    currency: matchedPlan ? matchedPlan.currency : subsection.currency, // Update currency if available
                    expertise: matchedPlan ? matchedPlan.serviceName : subsection.serviceName
                  };
                }),
              };
            }),
          };
        });
      });
    } catch (error) {
      setError("Error fetching tile data");
      console.error("Error fetching tile data:", error);
    }
  };

  // // Fetch data only when country changes
  // useEffect(() => {
  //   const storedData = window.sessionStorage.getItem(`servicePlans_${country}`);

  //   // Check if the data for the current country exists in localStorage
  //   if (storedData) {
  //     // console.log("Using cached data for country: ", country);
  //     const servicePlans = JSON.parse(storedData);

  //     // Map the service plans to update the data from localStorage
  //     setData((prevData) => {
  //       return prevData.map((platform) => {
  //         return {
  //           ...platform,
  //           sections: platform.sections.map((section) => {
  //             return {
  //               ...section,
  //               subsections: section.subsections.map((subsection) => {
  //                 // Find the matched service plan for the given country
  //                 const matchedPlan = servicePlans.find(
  //                   (plan) => plan.serviceId === subsection.serviceId
  //                 );

  //                 return {
  //                   ...subsection,
  //                   cost: matchedPlan ? matchedPlan.cost : subsection.rate, // Update rate with the cached cost
  //                   currency: matchedPlan ? matchedPlan.currency : subsection.currency, // Update currency if available
  //                   expertise: matchedPlan ? matchedPlan.serviceName : subsection.serviceName
  //                 };
  //               }),
  //             };
  //           }),
  //         };
  //       });
  //     });
  //   } else {
  //     console.log("Fetching new data for country: ", country);
  //     // If no data in localStorage, fetch data
  //     fetchTileData();
  //   }
  // }, [country]); // Run when the country changes

  useEffect(() => {
    const storedData = window.sessionStorage.getItem(`servicePlans_${country}`);
  
    // Check if the data for the current country exists in sessionStorage
    if (storedData) {
      try {
        // Attempt to parse the stored data
        const servicePlans = JSON.parse(storedData);
  
        // Check if the parsed servicePlans is an array and has data
        if (Array.isArray(servicePlans) && servicePlans.length > 0) {
          // Map the service plans to update the data from sessionStorage
          setData((prevData) => {
            return prevData.map((platform) => {
              return {
                ...platform,
                sections: platform.sections.map((section) => {
                  return {
                    ...section,
                    subsections: section.subsections.map((subsection) => {
                      // Find the matched service plan for the given country
                      const matchedPlan = servicePlans.find(
                        (plan) => plan.serviceId === subsection.serviceId
                      );
  
                      // Safeguard against missing matchedPlan or invalid data
                      return {
                        ...subsection,
                        cost: matchedPlan ? matchedPlan.cost : subsection.rate,
                        currency: matchedPlan ? matchedPlan.currency : subsection.currency,
                        expertise: matchedPlan ? matchedPlan.serviceName : subsection.serviceName
                      };
                    }),
                  };
                }),
              };
            });
          });
        } else {
          console.error("Invalid or empty service plans data for country:", country);
          fetchTileData(); // Fetch new data if parsed data is invalid
        }
      } catch (error) {
        // If JSON parsing fails, log the error and fetch new data
        console.error("Error parsing sessionStorage data for country:", country, error);
        console.log("fetching new data")
        fetchTileData();
      }
    } else {
      console.log("No data found in sessionStorage, fetching new data for country:", country);
      // If no data in sessionStorage, fetch new data
      fetchTileData();
    }
  }, [country]); // Run when the country changes
  


  // Helper function to assign border colors
  const getBorderColor = (index) => {
      const colors = ["blue", "green", "red", "yellow", "orange", "purple", "teal"];
      const randomIndex = Math.floor(Math.random() * colors.length); // Generate a valid index
      return colors[randomIndex];
  };

  return (
    <div className="pricing-container">
      {data.map((platform, platformIndex) => (
        <div key={platformIndex} className="platform-container">
          <h1 className="main-heading">{platform.title}</h1>
          <div className="sections-container" 
          style={platform.title === "SuccessFactors Solution and Support" && screenWidth > 1100 ? { width: "50%" } : {}}
          >
            {platform.sections.map((section, sectionIndex) => (
              <div key={sectionIndex} className="section-container">
                <h2>{section.title}</h2>
                <div className="subsection-container">
                  {section.subsections.map((subsection, subsectionIndex) => (
                    <div className="card-wraper" key={subsectionIndex}>
                      <div
                        className={`pricing-card ${`top-border-${subsection.color}`}`}
                      >
                        <h3 className="sub-heading">{subsection.expertise}</h3>
                        <p className="price">
                          <p>
                            {getCurrencySymbol(subsection.currency)}
                            <span className="rate" style={{ marginRight: "2px"}}>
                              {subsection.cost + " "}
                            </span>
                            / Hour
                          </p>
                        </p>
                        <ul className="list-items">
                          {subsection.list.map((item, itemIndex) => (
                            <li key={itemIndex} style={{ color: "black" }}>
                              {item}
                            </li>
                          ))}
                        </ul>
                      </div>
                    {/*      <button
                        className="learnmore-btn"
                        style={{ fontWeight: "700" }}
                      >
                        Learn More
                      </button> */}
                      <Link
                        className="sign-up-btn"
                        to="/us/signup"
                        onClick={() => window.scrollTo({ top: 0 })}
                        style={{
                          backgroundColor: "black",
                          color: "white",
                          fontWeight: "700",
                        }}
                      >
                        Sign up
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PricingCard;
