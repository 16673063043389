import React, { useState } from "react";
import "../../Styles/RecruitmentProcess.css";
 
const RecruitmentProcess = () => {
  const [activeTab, setActiveTab] = useState("experienced"); // Tracks the selected tab
  const [activeStep, setActiveStep] = useState("Explore your fit"); // Tracks the selected step
 
  // Content for Experienced Level
  const experiencedContent = {
    "Explore your fit": "Spend time on our website to learn more about our organization.",
    "Job search": "Search for open positions via our job map and by exploring current openings. Once you find a role that interests you, submit an application online.",
    "Apply": "Found a role that speaks to you? Just hit 'apply' on the job posting and complete our brief online application.",
    "Resume review": "Once your application has been submitted, your resume and qualifications will be reviewed.",
    "Interview": "If selected for an interview, you will be contacted by a member of our recruiting team to schedule an initial phone interview. The process may include behavioral, technical, or case-based interviews.",
    "Offer": "You will be notified of your status as quickly as possible. If you are receiving an offer, you will likely have it in writing within one week of your final interview.",
    "Prepare to start": "Prepare to start your journey with us."
  };
 
  // Content for Student Level
  const studentContent = {
    "Explore your fit": "Discover internship and early career opportunities tailored for students.",
    "Job search": "Use our job map to search for internships and student-focused programs.",
    "Apply": "Apply for a position by completing the online application.",
    "Resume review": "Your resume will be reviewed, and we’ll contact you if you’re shortlisted for an interview.",
    "Interview": "Interviews typically include behavioral and technical rounds designed for students.",
    "Offer": "Successful candidates will receive an offer to join our internship or graduate programs.",
    "Prepare to start": "Get ready to kickstart your career with BAPS!"
  };
 
  // Select the content based on the active tab
  const currentContent = activeTab === "experienced" ? experiencedContent : studentContent;
 
  // Steps to display (as keys from the content object)
  const steps = Object.keys(currentContent);
 
  return (
    <div className="recruitment-process">
      {/* Tabs for switching between Experienced Level and Student Level */}
      <div className="tabs">
        <button
          className={`tab-button ${activeTab === "experienced" ? "active" : ""}`}
          onClick={() => {
            setActiveTab("experienced");
            setActiveStep("Explore your fit"); // Reset to first step when switching tabs
          }}
        >
          Experienced Level
        </button>
        <button
          className={`tab-button ${activeTab === "student" ? "active" : ""}`}
          onClick={() => {
            setActiveTab("student");
            setActiveStep("Explore your fit"); // Reset to first step when switching tabs
          }}
        >
          Student Level
        </button>
      </div>
 
      {/* Horizontal steps with clickable circles */}
      <div className="steps-parent">
        <div className="steps">
          {steps.map((step, index) => (
            <div
              key={step}
              className={`step ${activeStep === step ? "active" : ""}`}
              onClick={() => setActiveStep(step)}
              onMouseEnter={() => setActiveStep(step)} // Show content on hover
              onMouseLeave={() => setActiveStep(activeStep)} // Revert to previous step
            >
              <div
                className="step-circle"
                style={index < 6 ? { marginRight: "60px" } : {}}
              >
                {steps.indexOf(step) + 1}
                <div className="step-title">{step}</div>
              </div>
            </div>
          ))}
          <hr className="line" />
        </div>
      </div>
 
      {/* Display the content for the active step */}
      <div className="step-description-container">
        <p>{currentContent[activeStep]}</p>
      </div>
    </div>
  );
};
 
export default RecruitmentProcess;