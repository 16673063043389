import React from 'react'
import PricingTable from './PricingTable'

const Pricing = () => {

    const pricingData = [
        {
            solutionsCount: "20",
            discount: "13%",
            hourPrice: "$195",
            monthlyPrice: "$3,900",
            rollover:"10%"
        },
        {
            solutionsCount: "35",
            discount: "15%",
            hourPrice: "$190",
            monthlyPrice: "$6,650",
            rollover:" 15%"
        },
        {
            solutionsCount: "50",
            discount: "17%",
            hourPrice: "$185",
            monthlyPrice: "$9,250",
            rollover:"20%"
        },
        {
            solutionsCount: "100",
            discount: "22%",
            hourPrice: "$175",
            monthlyPrice: "$17,500",
            rollover:" 40%"
        },
        {
            solutionsCount: "150",
            discount: "24%",
            hourPrice: "$170",
            monthlyPrice: "$25,500",
            rollover:"60%"
        },
        {
            solutionsCount: "200",
            discount: "28%",
            hourPrice: "$160",
            monthlyPrice: "$32,000",
            rollover:"80%"
        }
    ]

    const circle = ["","","","","","",""]
    
  return (
    <div className='pricing-container'>
        <div className='pricing-wrapper'>
            <div className='pricing-header'>
                <h1>Subscription Pricing</h1>
                <p>Our membership options offer monthly "bundles" of work that guarantee support services. Because of their customizable architecture, you can tier up or down these programs to suit your needs and business cycles. This guarantees that you receive the appropriate amount of assistance to successfully navigate your changing surroundings.</p>
            </div>

            {/* <ul className='pricing-tiles-container'>
                {pricingData.map((data,index)=>(
                    <li className='pricing-tile' key={index}>
                        <div className='tile-header'>
                            <p>{data.solutionsCount} Solutions</p>
                            <p>Hours</p>
                            <p>{data.discount} Discount</p>
                        </div>

                        <div className='pricing-details-container'>
                            <div className='prices'>
                                <p>{data.hourPrice} Per Hour</p>
                                <p>{data.monthlyPrice} Per Month</p>
                            </div>
                            <div className='rollover'>Rollover Banking - {data.rollover}</div>
                        </div>
                    </li>
                ))}

            </ul> */}
        </div>

        <PricingTable/>

    </div>
  )
}

export default Pricing