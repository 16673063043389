import React, { useState, useEffect, useRef, useCallback } from 'react';
import "../Styles/Dropdown.css"; // Assuming you have a CSS file for styles
import { IoIosArrowDown } from "react-icons/io"; // Import the IoIosArrowDown icon
import { MdClear } from "react-icons/md";

// Checkbox Option Component
const CheckboxOption = ({ label, checked, onChange }) => {
  return (
    <div className="checkbox">
      <input type="checkbox" checked={checked} onChange={onChange} />
      <label>{label}</label>
    </div>
  );
};

const Dropdown = ({
  isSelected, 
  setIsSelected, 
  selectedOptions, 
  setSelectedOptions, 
  error = '', // New prop to handle error message
}) => {
  const options = [
    { 
      label: 'Core & Talent', 
      checkboxes: ['SuccessFactor Core Modules Solution and Support Services', 'SuccessFactors Talent Management Solution and Support Services'] 
    },
    { 
      label: 'Integration', 
      checkboxes: ['SAP Cloud Integration', 'SAP Integration with HCM or S/4'] 
    },
    { 
      label: 'Analytics', 
      checkboxes: ['HR Analytics and Dashboards', 'Financial Analytics'] 
    },
    { 
      label: 'Business Process Automation', 
      checkboxes: ['Automated Workflow Design', 'Custom Process Automation'] 
    },
    { 
      label: 'Build Custom Applications', 
      checkboxes: ['Custom Application Development', 'API Development'] 
    }
  ];

  const [expandedOptions, setExpandedOptions] = useState(
    options.reduce((acc, option) => {
      acc[option.label] = false;
      return acc;
    }, {})
  );

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const dropdownRef = useRef(null);

  // Close dropdown if clicked outside the dropdown container
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleCheckboxChange = useCallback((label) => {
    setSelectedOptions((prevState) => {
      const newState = {
        ...prevState,
        [label]: !prevState[label],
      };

      const anySelected = Object.values(newState).includes(true);
      setIsSelected(anySelected); // Update isSelected based on whether any option is checked
      return newState;
    });
  }, [setSelectedOptions, setIsSelected]);

  const handleToggle = useCallback((optionLabel) => {
    setExpandedOptions((prevState) => ({
      ...prevState,
      [optionLabel]: !prevState[optionLabel],
    }));
  }, []);

  const handleDropdownToggle = () => {
    setDropdownVisible((prevState) => !prevState);
  };

  const handleDoneClick = () => {
    setDropdownVisible(false);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const selectedLabels = Object.keys(selectedOptions)
    .filter((key) => selectedOptions[key])
    .map((key) => key);

  const filteredOptions = options.filter(option => 
    option.label.toLowerCase().includes(searchQuery.toLowerCase()) || 
    option.checkboxes.some(checkbox => checkbox.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  const handleClear = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedOptions({});
    setIsSelected(false);
  };

  return (
    <div 
      className={`dropdown-container ${isSelected ? '' : 'not-selected'}`} 
      ref={dropdownRef}
    >
      <div 
        style={{
          color: selectedLabels.length > 0 ? "black" : "grey"
        }}
        className={`dropdown-trigger ${error ? 'dropdown-error' : ''}`}
        onClick={handleDropdownToggle}
      >
        {selectedLabels.length === 0 ? "Any Solution*" : selectedLabels.slice(0, 3).map((data, index) => <div key={index}>{data.substring(0,6) + "..."}</div>)}
        {selectedLabels.length > 3 && <div>+{selectedLabels.length - 3} more</div>}
        {selectedLabels.length !== 0 && <MdClear className='clear-icon' onClick={handleClear} />}
      </div>
      
      {error && (
        <span 
          style={{
            fontSize: "12px",
            paddingLeft: "14px",
            opacity: "0.8",
            letterSpacing: "0.6px"
          }}
          className="error"
        >
          {error}
        </span>
      )}

      {dropdownVisible && (
        <div className="dropdown-options-container">
          <div className="search-bar">
            <input 
              type="text" 
              placeholder="Search..." 
              value={searchQuery} 
              onChange={handleSearchChange} 
            />
          </div>

          <div className="options-list">
            {filteredOptions.map((option) => (
              <div key={option.label}>
                <div 
                    className="option-header" 
                    onClick={() => handleToggle(option.label)}
                >
                  <span>{option.label}</span>
                  <span className={`arrow ${expandedOptions[option.label] ? 'expanded' : ''}`}>
                    <IoIosArrowDown className='arrow' />
                  </span>
                </div>

                {expandedOptions[option.label] && (
                  <div className="checkboxes">
                    {option.checkboxes.map((checkboxLabel) => (
                      <CheckboxOption
                        key={checkboxLabel}
                        label={checkboxLabel}
                        checked={selectedOptions[checkboxLabel] || false}
                        onChange={() => handleCheckboxChange(checkboxLabel)}
                      />
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className="done-button">
            <button 
              onClick={handleDoneClick} 
            >
              Done
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
