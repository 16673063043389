import "../Styles/Footer.css"

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { BiWorld } from "react-icons/bi";

import { FaLinkedinIn } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";
import { IoMailOpen } from "react-icons/io5";
import { AiFillInstagram } from "react-icons/ai";

const Footer = () => {

    const [socialLinks,setSocialLinks] = useState([
        {
            logo: <FaLinkedinIn className='social-link-logo'/>,
            url: "https://www.linkedin.com/company/bapsconsulting/about/",
            name: "linkedin"
        },
        // {
        //     logo: <FaFacebookF className='social-link-logo'/>,
        //     url: "",
            //    name: "facebook"
        // },
        // {
        //     logo: <FaTwitter className='social-link-logo'/>,
        //     url: "",
        //     name: "twitter"
        // },
        {
            logo: <IoMailOpen className='social-link-logo'/>,
            url: "mailto:contactus@bapsconsulting.com",
            name: "mail"
        },
        // {
        //     logo: <AiFillInstagram className='social-link-logo'/>,
        //     url: "",
        //    name: "instagram"
        // },
    ])

    const [colum1Links, setColumn1Links] = useState([
        // {
        //     title: "Sitemap",
        //     toPath: "#"
        // },

        // {
        //     title: "Careers",
        //     toPath: "#"
        // },
        {
            title: "Services",
            toPath: "https://bapsconsulting.com/consulting/"
        },
        {
            title: "Products",
            toPath: "https://bapsconsulting.com/time-management-2/"
        },
        {
            title: "About Us",
            toPath: "https://bapsconsulting.com/about/"
        },
        // {
        //     title: "Submit RFP",
        //     toPath: "/"
        // },
        {
            title: "Home",
            toPath: "https://bapsconsulting.com/"
        },
    ])

    const [colum2Links, setColumn2Links] = useState([
        // {
        //     title: "Terms of use",
        //     toPath: "#"
        // },
        // {
        //     title: "Privacy",
        //     toPath: "#"
        // },
        // {
        //     title: "Disclaimer",
        //     toPath: "#"
        // },
        // {
        //     title: "Terms & Conditions",
        //     toPath: "#"
        // },
        // {
        //     title: "Cookies",
        //     toPath: "#"
        // }
    ])
  return (
    <div className="footer-container">
        <div className='footer'>
            <div className='footer-title'>
            </div>
            <div className='footer-sub-title'>
                <div className='footer-our-offices'>
                    <BiWorld className='world-logo'/>
                    <a 
                        href ="https://bapsconsulting.com/contact-us/" 
                        className='link'
                        // onClick={()=>window.scrollTo({top:0})}
                    >Our Offices</a>
                </div>
                <a 
                    href ="https://bapsconsulting.com/about/" 
                    className='link'
                    // onClick={()=>window.scrollTo({top:0})}
                    >About BAPS</a>
            </div>
            <div className='footer-links-wraper'>
                <div className='footer-links'>
                    <div className='footer-link-cards'>
                        {colum1Links.map((data,index)=>(
                            <a 
                                key={index}
                                className='link' 
                                href = {data.toPath}
                                // onClick={() => window.scrollTo({ top: 0})}
                            >{data.title}</a>
                        ))}
                    </div>
                    
                    <div className='footer-link-cards'>
                        {colum2Links.map((data,index)=>(
                            <Link 
                                className='link' 
                                key={index}
                                to = {data.toPath}
                                onClick={() => window.scrollTo({ top: 0})}
                            >{data.title}</Link>
                        ))}
                    </div >
                </div>
                <div className='footer-social-links'>
                    {/* <h1 className="footer-social-links-title">Contact</h1> */}
                    <div className='social-links'>
                        {socialLinks.map((links,index)=>(
                            <a 
                                href = {links.url} 
                                key={index} 
                            >{links.logo}</a>
                        ))}
                    </div>
                </div>
            </div>
            <div className='copyright-container'>
                <p>© 2024 BAPS. All Rights Reserved.</p>
            </div>
        </div>
    </div>
  )
}

export default Footer