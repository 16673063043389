import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "../Styles/NotFoundPage.css"

const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/"); // Redirect to your homepage or a specific route
    window.scrollTo({top:0})
  };

  return (
    <div className="not-found-container">
    <div className="not-found-content-wraper">
      <div className="not-found-content">
        <h1 className="title">
          Oops! The page you are looking for doesn’t exist. 
          It might have been moved or deleted.
        </h1>
        <p>The page you have selected may have moved or is no longer available.</p>

        <div className="link-container">
            <p>Instead, kindly try:</p>
            <div className="link-wraper">
                <p>•</p>
                <Link 
                    to ="/"
                    className="link"
                    onClick={()=> window.scrollTo({top:0})}
                >BAPS Home</Link>
            </div>
            <div className="link-wraper">
                <p>•</p>
                <Link 
                    to ="/"
                    className="link"
                    onClick={()=> window.scrollTo({top:0})}
                >Our Services</Link>
            </div>
            <div className="link-wraper">
                <p>•</p>
                <Link 
                    to ="/"
                    className="link"
                    onClick={()=> window.scrollTo({top:0})}
                >About Us</Link>
            </div>

        </div>
        <p>If you received this error when you clicked on a URL from our website, please <Link className="contact-link" to ="/us/about">contact us</Link>.</p>
        <button 
            className="go-back-button" 
            onClick={handleGoBack}>
          Go back To home 
        </button>
      </div>
        <h1>404</h1>
      </div>
    </div>
  );
};

export default NotFoundPage;
