import React, { useEffect, useRef } from "react";
import "../Styles/CoreOfferings.css";
import im from "../Images/coreOfferings.png"
import im1 from "../Images/coreOfferings.png"; 
import employee_central from "../Images/Core/employee_central.png"
import goals from "../Images/Core/goals.png"
import learn from "../Images/Core/learn.png"
import onboarding from "../Images/Core/onboarding.png"
import compensation from "../Images/Core/compensation.png"
import succession from "../Images/Core/succession.png"
import automation from "../Images/Core/automation.png"



const CoreOfferings = () => {
  const sections = [
    {
      image: employee_central,
      heading: "SuccessFactors Employee Central",
      subtitle: "Empower Your Workforce with Seamless HR Management",
      introText: "SuccessFactors Employee Central is a comprehensive, SaaS-based HR solution designed to empower your workforce with seamless HR management.",
      content: [
        "Lower Total Cost of Ownership (TCO) compared to on-premise systems",
        "A hire-to-retire system tailored for the modern workforce",
        "Centralized system of record to unify all HR functions in one place",
        "Transformative workforce management, driving business impact and success"
      ]
    },
    {
      image: goals,
      heading: "SuccessFactors Performance & Goals",
      subtitle: "Drive Continuous Growth and Goal Alignment",
      introText: "SuccessFactors Performance & Goals helps organizations drive continuous growth through clear alignment of individual performance with business strategy.",
      content: [
        "Clear alignment of individual performance with organizational strategy",
        "Ongoing coaching and feedback to drive continuous improvement",
        "Real-time goal tracking to adapt quickly to evolving business needs",
        "Accurate performance evaluation and recognition for top talent"
      ]
    },
    {
      image: compensation,
      heading: "SuccessFactors Compensation",
      subtitle: "Transform Compensation into a Strategic Advantage",
      introText: "SuccessFactors Compensation allows businesses to optimize and align compensation programs with organizational objectives, ensuring fair and competitive pay.",
      content: [
        "Strategic alignment of compensation programs with business objectives",
        "Data-driven insights to create competitive compensation packages",
        "Tools for attracting, retaining, and motivating top talent",
        "Comprehensive compensation management to optimize rewards and performance"
      ]
    },
    {
      image: learn,
      heading: "SuccessFactors Learning & Development",
      subtitle: "Foster a Culture of Continuous Learning",
      introText: "SuccessFactors Learning & Development offers a range of tools to drive employee growth and compliance through a culture of continuous learning.",
      content: [
        "Enhanced employee skills and continuous development",
        "Compliance risk reduction with comprehensive training solutions",
        "Flexible and open content support for varied learning needs",
        "Proven success in driving business results through effective training"
      ]
    },
    {
      image: succession,
      heading: "SuccessFactors Succession & Development",
      subtitle: "Cultivate Future Leaders and Minimize Risk",
      introText: "SuccessFactors Succession & Development helps organizations identify and nurture future leaders to ensure business continuity and long-term success.",
      content: [
        "Identify and nurture high-potential talent for leadership roles",
        "Build a talent pipeline to ensure long-term business success",
        "Minimize risk by developing talent for future growth and challenges",
        "Provide visibility and planning capabilities for future leadership needs"
      ]
    },
    {
      image: onboarding,
      heading: "SuccessFactors Recruiting & Onboarding",
      subtitle: "Attract, Hire, and Retain Top Talent Faster",
      introText: "SuccessFactors Recruiting & Onboarding streamlines recruitment and onboarding to accelerate talent acquisition and improve employee retention.",
      content: [
        "A seamless recruitment process to source and engage top talent",
        "Guided steps for recruiters, hiring managers, and candidates",
        "Faster onboarding to improve new hire retention and productivity",
        "A focus on people, processes, and productivity for long-term success"
      ]
    },
    {
      image: automation,
      heading: "SAP Core HCM",
      subtitle: "Optimize Workforce Management for Organizational Success",
      introText: "SAP Core HCM provides a comprehensive platform to automate and streamline key HR processes like payroll, recruiting, and employee management.",
      content: [
        "Automation and streamlining of core HR processes, including workforce management, time and attendance, recruiting, and payroll",
        "Maximizing employee potential by aligning skills with business goals, driving growth and productivity",
        "Compliance support to navigate changing global and local HR regulations",
        "Tools to measure, evaluate, and optimize workforce contributions to the company’s bottom line"
      ]
    }
  ];
 
  // Refs for sections
  const sectionRefs = useRef(sections.map(() => React.createRef()));
 
  useEffect(() => {
    // Initialize Intersection Observer
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        const section = entry.target;
        const heading = section.querySelector(".core-offerings-heading");
 
        if (heading) {
          if (entry.isIntersecting) {
            // Add animation class when the section is in view
            heading.classList.add("slide-in-text");
          } else {
            // Remove animation class when the section is out of view
            heading.classList.remove("slide-in-text");
          }
        }
      });
    }, { threshold: 0.5 }); // Trigger when 50% of the section is visible
 
    sectionRefs.current.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current); // Observe the entire section
      }
    });
 
    return () => {
      observer.disconnect(); // Cleanup observer on component unmount
    };
  }, []);
 
  return (
    <div className="core-offerings-tiles-wraper">
    <div className="core-offerings-container" style={{
        paddingBottom: "0px"
    }}>
      {sections.map((section, index) => (
        <div
          className={`core-offerings-section ${index % 2 === 1 ? "reversed" : ""}`}
          key={index}
          ref={sectionRefs.current[index]} // Observe this section
        >
          <div className="core-offerings-image">
            <img className = "section-img" src={section.image} alt={section.heading}/>
            <div className="core-offerings-heading">
              <h2>{section.heading}</h2>
              <p>{section.subtitle}</p>
            </div>
          </div>
          <div className="core-offerings-content">
            <h3>{section.heading}</h3>
            {/* Use the unique introText for each section */}
            <p>{section.introText}</p>
 
            {/* Dynamically rendered content list */}
            <ul>
              {section.content.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
    </div>
  );
};
 
export default CoreOfferings;
 
 