import React, { useState } from 'react'
import "../../Styles/Login.css"
import LoginForm from './LoginForm'
import SignupForm from './SignupForm'
import { Route, Routes } from 'react-router-dom'
import signupBanner from "../../Images/login/signup.png"

const LoginPage = () => {


  return (
    <div className='login-container-wraper'>
      <div className='login-container'>
          {/* <div className='img-container'>
              <div className='content-wraper'>
                  <h1>One Support</h1>
                  <p>powered by <span style={{
                    color: "#E8B91F",
                    fontSize: "20px",
                    fontWeight: "600"
                  }}>BAPS</span></p>
              </div>
              <div className='left-div'></div>
          </div> */}
          <div className='login-wraper'>
              {/* <LoginForm/>
              <SignupForm/> */}
            <Routes>
              {/* Route to show the login form */}
              <Route path="/login" element={<LoginForm />} />

              {/* Route to show the signup form */}
              <Route path="/signup" element={<SignupForm />} />
            </Routes>
          </div>
      </div>
      <img src = {signupBanner} className='sign-up-banner'/>
    </div>
  )
}

export default LoginPage


