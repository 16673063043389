import React, { useState } from 'react';
import '../../Styles/JobSearch.css';
 
import { FaMedal } from "react-icons/fa6";
import { GiProcessor } from "react-icons/gi";
import { GiWorld } from "react-icons/gi";
import RecruitmentProcess from './RecruitmentProcess';
 
const JobSearch = () => {
  // Sample job data - replace with your actual data
  const initialJobs = [
    {
      id: 1,
      title: "React Developer",
      company: "Tech Solutions Inc",
      location: "New York, NY",
      type: "Full-time",
      experience: "3-5 years",
      salary: "$90,000 - $120,000",
      description: "We are looking for an experienced React developer to join our team...",
      skills: ["React", "JavaScript", "TypeScript", "Redux"]
    },
    {
      id: 2,
      title: "Frontend Engineer",
      company: "Digital Dynamics",
      location: "Remote",
      type: "Contract",
      experience: "2-4 years",
      salary: "$80,000 - $100,000",
      description: "Seeking a talented frontend engineer with strong UI/UX skills...",
      skills: ["HTML", "CSS", "JavaScript", "Vue.js"]
    },{
        id: 3,
        title: "Full Stack Developer",
        company: "Innovation Labs",
        location: "San Francisco, CA",
        type: "Full-time",
        experience: "5+ years",
        salary: "$120,000 - $150,000",
        description: "Join our dynamic team as a Full Stack Developer...",
        skills: ["Node.js", "React", "MongoDB", "AWS"]
      },
      {
        id: 4,
        title: "Backend Developer",
        company: "Tech Innovators",
        location: "Austin, TX",
        type: "Part-time",
        experience: "3-5 years",
        salary: "$70,000 - $90,000",
        description: "Looking for a skilled backend developer with experience in Node.js...",
        skills: ["Node.js", "Express", "PostgreSQL", "Docker"]
      },
      {
        id: 5,
        title: "UI/UX Designer",
        company: "Creative Minds",
        location: "Remote",
        type: "Full-time",
        experience: "2-4 years",
        salary: "$60,000 - $80,000",
        description: "Join our creative team as a UI/UX designer to create stunning user interfaces...",
        skills: ["Figma", "Adobe XD", "Sketch", "Prototyping"]
      },
      {
        id: 6,
        title: "Data Scientist",
        company: "Data Analytics Co.",
        location: "Chicago, IL",
        type: "Full-time",
        experience: "5+ years",
        salary: "$110,000 - $140,000",
        description: "Seeking a data scientist to derive insights from complex datasets...",
        skills: ["Python", "R", "Machine Learning", "SQL"]
      },
      {
        id: 7,
        title: "DevOps Engineer",
        company: "Cloud Solutions",
        location: "Seattle, WA",
        type: "Contract",
        experience: "3-5 years",
        salary: "$100,000 - $130,000",
        description: "We are hiring a DevOps engineer with expertise in cloud services...",
        skills: ["AWS", "Kubernetes", "CI/CD", "Terraform"]
      },
      {
        id: 8,
        title: "Marketing Specialist",
        company: "Brand Builders",
        location: "Los Angeles, CA",
        type: "Full-time",
        experience: "2-4 years",
        salary: "$50,000 - $70,000",
        description: "Join our team to drive digital marketing campaigns and brand strategy...",
        skills: ["SEO", "Google Ads", "Content Marketing", "Analytics"]
      },
      {
        id: 9,
        title: "Mobile App Developer",
        company: "AppWorks Inc",
        location: "New York, NY",
        type: "Full-time",
        experience: "3-5 years",
        salary: "$90,000 - $120,000",
        description: "We are looking for a skilled mobile app developer with experience in Flutter...",
        skills: ["Flutter", "Dart", "Kotlin", "iOS/Android"]
      },
      {
        id: 10,
        title: "Machine Learning Engineer",
        company: "AI Innovations",
        location: "Remote",
        type: "Full-time",
        experience: "5+ years",
        salary: "$120,000 - $160,000",
        description: "Seeking a machine learning engineer to build scalable AI solutions...",
        skills: ["TensorFlow", "PyTorch", "Deep Learning", "Python"]
      },
      {
        id: 11,
        title: "Technical Writer",
        company: "DocsPro",
        location: "Remote",
        type: "Part-time",
        experience: "2+ years",
        salary: "$40,000 - $60,000",
        description: "Looking for a technical writer to create clear documentation for our software...",
        skills: ["Technical Writing", "Markdown", "Documentation Tools", "Git"]
      },
      {
        id: 12,
        title: "Cloud Architect",
        company: "Cloudify Solutions",
        location: "San Francisco, CA",
        type: "Full-time",
        experience: "5+ years",
        salary: "$150,000 - $180,000",
        description: "We are hiring a cloud architect to design scalable cloud infrastructures...",
        skills: ["AWS", "Azure", "GCP", "Kubernetes"]
      },
      {
        id: 13,
        title: "IT Support Specialist",
        company: "TechCare",
        location: "Chicago, IL",
        type: "Full-time",
        experience: "1-3 years",
        salary: "$40,000 - $50,000",
        description: "Provide IT support to clients and resolve technical issues...",
        skills: ["Troubleshooting", "Hardware", "Software Support", "Customer Service"]
      },
      {
        id: 14,
        title: "Product Manager",
        company: "Innovative Products",
        location: "Austin, TX",
        type: "Full-time",
        experience: "5+ years",
        salary: "$130,000 - $160,000",
        description: "Lead product development initiatives as a key product manager...",
        skills: ["Agile", "Scrum", "Leadership", "Roadmap Planning"]
      },
      {
        id: 15,
        title: "Cybersecurity Analyst",
        company: "SecureTech",
        location: "Washington, D.C.",
        type: "Full-time",
        experience: "3-5 years",
        salary: "$100,000 - $130,000",
        description: "Protect our digital assets as a cybersecurity analyst...",
        skills: ["Network Security", "Firewalls", "Ethical Hacking", "SIEM"]
      }
  ];
 
 
  const [jobs, setJobs] = useState(initialJobs);
  const [filters, setFilters] = useState({
    location: '',
    type: '',
    experience: '',
  });
  const [searchQuery, setSearchQuery] = useState('');
 
  // Handle search input
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    filterJobs(query, filters);
  };
 
  // Handle filter changes
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    const newFilters = { ...filters, [name]: value };
    setFilters(newFilters);
    filterJobs(searchQuery, newFilters);
  };
 
  // Filter jobs based on search and filters
  const filterJobs = (query, currentFilters) => {
    let filteredJobs = initialJobs.filter((job) => {
      const matchesSearch =
        job.title.toLowerCase().includes(query) ||
        job.company.toLowerCase().includes(query) ||
        job.description.toLowerCase().includes(query);
 
      const matchesLocation = !currentFilters.location || job.location.includes(currentFilters.location);
      const matchesType = !currentFilters.type || job.type === currentFilters.type;
      const matchesExperience = !currentFilters.experience || job.experience.includes(currentFilters.experience);
 
      return matchesSearch && matchesLocation && matchesType && matchesExperience;
    });
 
    setJobs(filteredJobs);
  };
 
  // Values to render
  const values = [
    {
      icon: <FaMedal className="value-icon" />,
      title: "Rewards & Benefits",
      description: "Your financial, mental, and physical health are important to us, and we have designed our benefits to help you in all area of your life.",
    },
    {
      icon: <GiWorld className="value-icon" />,
      title: "Life At BAPS",
      description: "We cherish honesty, decency, bravery, teamwork, and excellence. Find out more about the nature of a career at BAPS.",
    },
    {
      icon: <GiProcessor className="value-icon" />,
      title: "Hiring Process",
      description: "Discover what to anticipate from the employment process in a world that is socially and geographically distant.",
    },
  ];
 
  // State to manage hover
  const [isHovered, setIsHovered] = useState(false);
 
  return (
    <div className="job-search-container">
      {/* Search Section */}
 
 
      <div
        className={`intro-card ${isHovered ? 'hovered' : ''}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
         // Replace with the correct path to re.jpg
      >
        <div className="intro-card-header">
          <h2>Are you ready to join the future?</h2>
        </div>
        {isHovered && (
          <div className="intro-card-content">
            <p>
              Our team works together to bring about change. Our goal at BAPS is to revolutionize the way that work is done.
              We accomplish this by thoroughly researching the industries of our clients and learning about their distinctive traits and business goals.
            </p>
            <p>
              We are a SAP Gold Partner with a focus on retail and fashion, and we have chosen to specialize in three different areas: enterprise asset
              management, human capital management, and consumer industries. Our clients include mid-sized companies with solid growth trajectories and
              some very well-known brands you may have heard of.
            </p>
            <p>
              We're searching right now for people who think that working together, we can do so much more. Become a member of our vibrant team and
              contribute to the workplace of the future.
            </p>
          </div>
        )}
      </div>
 
      <RecruitmentProcess/>
      {/* Values Section */}
      <div className="values-container">
       
        <div className="values-grid">
          {values.map((value, index) => (
            <div className="value-card" key={index}>
              <div className="icon-container">{value.icon}</div> {/* Render the icon */}
              <h3 className="value-title">{value.title}</h3>
              <p className="value-description">{value.description}</p>
            </div>
          ))}
        </div>
      </div>
 
      {/* Filters Section */}
      <div className="search-section">
        <input
          type="text"
          placeholder="Search for jobs..."
          value={searchQuery}
          onChange={handleSearch}
          className="search-input"
        />
      </div>
 
      <div className="filters-section">
        <select
          name="location"
          value={filters.location}
          onChange={handleFilterChange}
          className="filter-select"
        >
          <option value="">All Locations</option>
          <option value="New York">New York</option>
          <option value="Remote">Remote</option>
          <option value="San Francisco">San Francisco</option>
        </select>
 
        <select
          name="type"
          value={filters.type}
          onChange={handleFilterChange}
          className="filter-select"
        >
          <option value="">All Types</option>
          <option value="Full-time">Full-time</option>
          <option value="Part-time">Part-time</option>
          <option value="Contract">Contract</option>
        </select>
 
        <select
          name="experience"
          value={filters.experience}
          onChange={handleFilterChange}
          className="filter-select"
        >
          <option value="">All Experience</option>
          <option value="0-2">0-2 years</option>
          <option value="3-5">3-5 years</option>
          <option value="5+">5+ years</option>
        </select>
      </div>
 
      {/* Jobs List */}
      <div className="jobs-list">
        {jobs.length === 0 ? (
          <div className="no-jobs">No jobs found matching your criteria</div>
        ) : (
          jobs.map((job) => (
            <div key={job.id} className="job-card">
              <div className="job-card-header">
                <h2>{job.title}</h2>
                <span className="company-name">{job.company}</span>
              </div>
 
              <div className="job-card-details">
                <div className="detail-item">
                  <span className="detail-icon">📍</span>
                  {job.location}
                </div>
                <div className="detail-item">
                  <span className="detail-icon">💼</span>
                  {job.type}
                </div>
                <div className="detail-item">
                  <span className="detail-icon">⭐</span>
                  {job.experience}
                </div>
                <div className="detail-item">
                  <span className="detail-icon">💰</span>
                  {job.salary}
                </div>
              </div>
 
              <p className="job-description">{job.description}</p>
 
              <div className="skills-container">
                {job.skills.map((skill, index) => (
                  <span key={index} className="skill-tag">
                    {skill}
                  </span>
                ))}
              </div>
 
              <button className="apply-button">Apply Now</button>
            </div>
          ))
        )}
      </div>
    </div>
  );
};
 
export default JobSearch;