import React, { useState, useEffect } from "react";
import "../Styles/OurCloud.css"; 
// Import images
import cost from "../Images/CostEfficiency.png";
import rollover from "../Images/rollover.png";
import fupgrade from "../Images/fupgrade.png";
import budget from "../Images/budget.png";
import support from "../Images/support.png";
import planning from "../Images/planning.png";
import priority from "../Images/priority.png";
import solution from "../Images/solutions.png";
import cloud from "../Images/cloud_transfer.png";

const carouselItems = [
  {
    id: 1,
    title: "Seamless Cloud Transformation",
    subtitle: "Effortless Migration to SuccessFactors Cloud",
    description:
      "Experience a smooth transition to SuccessFactors with our expert-driven implementation services. From initial assessments to full deployment, we guide you every step of the way to ensure minimal disruption and maximum value.",
    image: cloud,
  },
  {
    id: 2,
    title: "Tailored Solutions & Expert Configuration",
    subtitle: "Customization That Fits Your Business Needs",
    description:
      "Our team designs and implements SuccessFactors solutions tailored to your unique business requirements. With specialized knowledge across all SuccessFactors modules, we ensure your HR processes are optimized for efficiency and growth.",
    image: solution,
  },
  {
    id: 3,
    title: "Proactive AMS Support",
    subtitle: "Reliable, Ongoing Support & Maintenance",
    description:
      "Our Application Management Services (AMS) provide continuous monitoring, updates, and issue resolution. With proactive support, your SuccessFactors environment stays optimized, minimizing downtime and ensuring seamless performance.",
    image: rollover,
  },
  {
    id: 4,
    title: "Scalable & Flexible Subscription Plans",
    subtitle: "Support That Scales With You",
    description:
      "Choose from flexible, subscription-based plans that grow with your business. Whether you need full support or periodic assistance, our tailored bundles offer the right level of service at the best value, with discounts for long-term commitments.",
    image: budget,
  },
  {
    id: 5,
    title: "Data-Driven Insights & Continuous Improvement",
    subtitle: "Empower Decision-Making with Analytics",
    description:
      "Unlock the power of analytics with our ongoing support services. We provide data-driven insights that help you continuously optimize HR performance, ensuring your workforce aligns with business goals and drives measurable success.",
    image: fupgrade,
  },
  {
    id: 6,
    title: "Cost Efficiency",
    subtitle: "Maximizing Value, Minimizing Expenses",
    description:
      "The more hours you subscribe to, the greater the discount you receive. This makes our subscription plan a cost-effective solution for businesses looking to optimize their support budget.",
    image: cost,
  },
];

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleItems, setVisibleItems] = useState(4);

  useEffect(() => {
    const updateVisibleItems = () => {
      if (window.innerWidth < 768) {
        setVisibleItems(1); // 1 item for small screens
      } else if (window.innerWidth < 1024) {
        setVisibleItems(2); // 2 items for medium screens
      } else {
        setVisibleItems(4); // Default for large screens
      }
    };

    updateVisibleItems();
    window.addEventListener("resize", updateVisibleItems);
    return () => window.removeEventListener("resize", updateVisibleItems);
  }, []);

  const maxIndex = carouselItems.length - visibleItems;

  const handlePrevious = () => {
    setCurrentIndex((prev) => Math.max(prev - 1, 0));
  };

  const handleNext = () => {
    setCurrentIndex((prev) => Math.min(prev + 1, maxIndex));
  };

  return (
    <div className="carousel-wrapper">
      <h1 className="carousel-wrapper-title">Key Features:</h1>
      <div className="button-wrapper">
        <button
          onClick={handlePrevious}
          disabled={currentIndex === 0}
          className="nav-button nav-button-left"
        >
          ←
        </button>
        <button
          onClick={handleNext}
          disabled={currentIndex >= maxIndex}
          className="nav-button nav-button-right"
        >
          →
        </button>
      </div>

      <div className="carousel-container">
        <div
          className="carousel-track"
          style={{
            transform: `translateX(-${currentIndex * (100 / visibleItems)}%)`,
          }}
        >
          {carouselItems.map((item) => (
            <div key={item.id} className="carousel-item">
              <div
                className="carousel-item-image"
                style={{ backgroundImage: `url(${item.image})` }}
              />
              <div className="carousel-item-overlay">
                <div className="carousel-content">
                  <h3 className="carousel-title">{item.title}</h3>
                  <div className="carousel-description">
                    <h1 className="sub-title">{item.subtitle}</h1>
                    <p>{item.description}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Carousel;
